<template>
  <div class="newQuestion">
    <div v-if="isEdit" class="mb-2">
      <el-page-header
        @back="$router.go(-1)"
        :content="myTitle"
      ></el-page-header>
    </div>
    <Breadcrumb v-else>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("sat.QuestionsBox") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-alert v-if="isDuplicate" class="mb-3" type="warning" effect="dark" :closable="false" show-icon>
      <b>
        Duplicated! After you edit the question, click "Add" to add the
        question.
      </b>
    </el-alert>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <template>
      <el-form ref="form" label-width="180px">
        <el-form-item label="Version" required v-show="!isEdit" v-if="isAdmin">
          <el-radio-group v-model="isNew" @change="selectVersion">
            <el-radio-button :label="1">New Digital SAT</el-radio-button>
            <el-radio-button :label="0">Old Paper-Based SAT</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Subject" required>
          <el-radio-group v-model="sat_subject_id">
            <el-radio-button
              :key="subject.id"
              v-for="subject in subjectsNow"
              :label="subject.id"
            >
              {{ titleCase(subject.name) }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
    <SmartInput
      :isNew="isNew"
      :sat_subject_id="sat_subject_id"
      :sat_id="sat_id"
      :isEdit="isEdit"
      :isTeacher="isTeacher"
      :isAdmin="isAdmin"
      :isCompany="isCompany"
    ></SmartInput>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SmartInput from "@/views/satAddQuestion/components/SmartInput";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    SmartInput
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      showProgress: false,
      progress: 0,
      tags: [],
      smartTags: null,
      defaultTextarea: "",
      mathTextarea: "",
      smartType: "default",
      smartQuestionsText: "",
      smartMathQuestionsText: "",
      smartQuestions: [],
      inputType: 1,
      textarea: "",
      isNew: 1,
      sat: {
        is_new: 1,
        title: ""
      },
      sats: [],
      title: 0,
      needPassage: false,
      showCreateNewPassagesDialog: false,
      showCreateSATDialog: false,
      subjects: [],
      sat_id: null,
      sat_subject_id: 5,
      question: {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        difficulty: null,
        sat_question_tag_ids: [],
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      },
      passage: {
        introduction: [],
        content: "",
        below_content: ""
      }
    };
  },
  computed: {
    isTeacher() {
      return this.$route.path.indexOf("teacher") > -1;
    },
    isCompany() {
      return this.$route.path.indexOf("company") > -1;
    },
    isAdmin() {
      return this.$route.path.indexOf("admin") > -1;
    },
    totalProgress() {
      return this.smartQuestions.length;
    },
    placeholder() {
      return [
        `“Ghosts of the Old Year” is an early 1900s poem by James Weldon Johnson. In the poem, the speaker describes experiencing an ongoing cycle of anticipation followed by regretful reflection: ______
Which quotation from “Ghosts of the Old Year” most effectively illustrates the claim?
A) "The snow has ceased its fluttering flight, / The wind sunk to a whisper light, / An ominous stillness fills the night, / A pause—a hush."
B) "And so the years go swiftly by, / Each, coming, brings ambitions high, / And each, departing, leaves a sigh / Linked to the past."
C) "What does this brazen tongue declare, / That falling on the midnight air / Brings to my heart a sense of care / Akin to fright?"
D) "It tells of many a squandered day, / Of slighted gems and treasured clay, / Of precious stores not laid away, / Of fields unreaped."
B
Choice B is the best answer. The passage....`,
        `The \`y\`-intercept of the graph of \`y = −6x − 32\` in the \`xy\`-plane is \`(0, y)\`. What is the value of \`y\`?
-32
Choice B is the best answer. The passage...`
      ];
    },
    subjectsNow() {
      let subjects = [];
      if (this.isNew === 1) {
        subjects = [this.subjects[4], this.subjects[2]];
      } else {
        subjects = [
          this.subjects[0],
          this.subjects[1],
          this.subjects[2],
          this.subjects[3]
        ];
      }
      return subjects;
    },
    questionId() {
      return this.$route.query.questionId;
    },
    myTitle() {
      return this.isEdit
        ? "Edit Question"
        : this.isDuplicate
          ? "Duplicate Question"
          : "Create Question";
    },
    isEdit() {
      return (
        this.questionId &&
        this.questionId > 0 &&
        this.$route.name.indexOf("SatDuplicateQuestion") < 0
      );
    },
    isDuplicate() {
      return this.$route.name.indexOf("SatDuplicateQuestion") > -1;
    }
  },
  watch: {},

  async mounted() {
    console.log(this.$route);
    if (this.questionId && this.questionId > 0) {
      const res = await SAT.getQuestionDetail(this.questionId);
      const sat_question = res.sat_question;
      this.isNew = sat_question.is_new;
      this.question = { ...this.question, ...sat_question };
      this.passage = { ...this.passage, ...sat_question.sat_passage };
      if (
        sat_question.sat_question_tags &&
        sat_question.sat_question_tags.length > 0
      ) {
        sat_question.sat_question_tags.forEach(e => {
          this.question.sat_question_tag_ids.push(e.id);
        });
      }
      this.sat_id = sat_question.sat_sat_subject?.sat.id;
    }
    if (Number(this.$route.query.isNew) > -1) {
      this.isNew = Number(this.$route.query.isNew);
      if (this.isNew === 1) {
        this.sat_subject_id = 5;
      }
      if (this.isNew === 0) {
        this.sat_subject_id = 1;
      }
    }
    await this.getSATS();
    this.sat_id = 496;
    await this.getSubjects();
    await this.getTags();
  },
  methods: {
    reSetSmartQuestions() {
      this.smartQuestions = [];
      this.showProgress = false;
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    selectVersion(isNew) {
      this.$router.replace({
        name: "SatAddQuestion",
        query: {
          isNew: isNew
        }
      });
    },
    async getSubjects() {
      const subjects = await SAT.getSubjects();
      this.subjects = subjects.sat_subjects;
    },
    async getSATS() {
      const res = await SAT.getSATS({
        is_new: this.isNew
      });
      this.sats = res.sats;
    },
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },
    setPassage(value) {
      console.log(value);
      this.passage = { ...this.passage, ...value };
    },
    selectPassage(passage) {
      this.question.sat_passage_id = passage.id;
      this.passage = passage;
      this.showCreateNewPassagesDialog = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    closePassageDialog() {
      this.showCreateNewPassagesDialog = false;
    },
    async checkPassage() {
      if (this.question.sat_passage_id && this.question.sat_passage_id > 0) {
        const res = await SAT.getPassage(this.question.sat_passage_id);
        this.passage = res.sat_passage;
      } else {
        this.passage = {
          title: "",
          introduction: {
            intro: "",
            below_content: ""
          },
          content: ""
        };
      }
      this.showCreateNewPassagesDialog = true;
    },
    async saveNewSAT() {
      const res = await SAT.addNewSAT({ ...this.sat, is_new: this.isNew });
      this.getSATS();
      this.sat_id = res.sat.id;
      this.showCreateSATDialog = false;
    },
    async saveAll(type) {
      await this.saveNewPassage();
      if (this.questionId && this.questionId > 0) {
        await this.updateQuestion();
      } else {
        await this.saveQuestion();
      }
      if (type === "back") {
        this.$router.go(-1);
      } else {
        let box = document.getElementsByClassName("right-content")[0];
        box.scrollTo(0, 0);
      }
    },
    async saveSmartQuestions() {
      this.showProgress = true;
      this.progress = 0;
      if (this.smartQuestions && this.smartQuestions.length > 0) {
        for (let i = 0; i <= this.smartQuestions.length; i++) {
          setTimeout(async () => {
            let question = this.smartQuestions[i].question;
            let passage = this.smartQuestions[i].passage;
            if (passage.content && passage.content !== "") {
              const res = await SAT.addPassage({
                ...passage,
                sat_id: this.sat_id,
                sat_subject_id: this.sat_subject_id
              });
              question.sat_passage_id = res.sat_passage.id;
            }
            await SAT.addNewQuestion({
              ...question,
              sat_id: this.sat_id,
              is_new: this.isNew,
              sat_subject_id: this.sat_subject_id
            });
            this.progress++;
          }, i * 1000);
        }
      }
    },
    async saveNewPassage() {
      if (this.passage.content && this.passage.content !== "") {
        if (this.question.sat_passage_id && this.question.sat_passage_id > 0) {
          await SAT.updatePassage(this.question.sat_passage_id, {
            ...this.passage,
            title: this.passage.title ? this.passage.title : "",
            introduction: this.passage.introduction
              ? this.passage.introduction
              : []
          });
        } else {
          const res = await SAT.addPassage({
            ...this.passage,
            sat_id: this.sat_id,
            sat_subject_id: this.sat_subject_id
          });
          this.question.sat_passage_id = res.sat_passage.id;
        }
      }
      this.showCreateNewPassagesDialog = false;
    },
    async saveQuestion() {
      await SAT.addNewQuestion({
        ...this.question,
        sat_id: this.sat_id,
        is_new: this.isNew,
        sat_subject_id: this.sat_subject_id
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      this.question = {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        sat_question_tag_ids: [],
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      };
      this.passage = {
        introduction: [],
        content: "",
        below_content: ""
      };
    },

    smartOptions(str) {
      if (this.question.type === "default") {
        const {
          passage,
          content,
          options,
          explanation,
          answers
        } = this.smartQuestion(str);
        console.log(content, options, explanation);
        this.passage.content = null;
        this.passage.content = passage;
        if (content) {
          this.question.content = content;
        }
        if (options && options.length > 0) {
          options.forEach((option, index) => {
            this.question.sat_options[index].title = option;
          });
        }
        if (explanation) {
          this.question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          this.question.sat_answers[0].answers = answers;
        }
      } else {
        const { content, explanation, answers } = this.smartQuestionMath(str);
        if (content) {
          this.question.content = content;
        }
        if (explanation) {
          this.question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          this.question.sat_answers = [];
          answers.forEach((answer, index) => {
            this.question.sat_answers.push({
              answers: [answer]
            });
          });
        }
      }
    },
    removeQuestion(index) {
      console.log(index);
      this.smartQuestions.splice(index, 1);
    },
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },
    changeText(text) {
      // this.defaultTextarea = this.replaceAll(text, "</p><p>", "<br>");
    },
    smartQuestionMath(inputStr) {
      const htmlRegex = /<p>(.+?)<\/p>/s;
      const match = inputStr.match(htmlRegex);
      const info = match[1].split("<br>");
      const question = info[0];
      const answersText = info[1];
      const answers = answersText.split(",");
      const explanation = info[2];
      console.log([question, answers, explanation]);
      return {
        content: question,
        explanation: explanation,
        answers: answers
      };
    },
    smartQuestion(inputStr) {
      const htmlRegex = /<p>(.+?)<\/p>/s;
      const match = inputStr.match(htmlRegex);
      const info = match[1].split("<br>");
      // const question = info[1];
      const choices = [];
      let firstOptionIndex = -1;
      let lastOptionIndex = -1;
      for (let i = 0; i < info.length; i++) {
        if (info[i].match(/[A-Z][.)][^A-Z]+/g)) {
          if (firstOptionIndex === -1) {
            firstOptionIndex = i;
          }
          lastOptionIndex = i;
          let str = info[i]
            .trim()
            .slice(2)
            .trim();
          choices.push(str);
        }
      }
      // console.log(choices, firstOptionIndex);
      console.log(0, firstOptionIndex - 2);
      let passage = "";
      if (firstOptionIndex >= 2) {
        for (let i = 0; i <= firstOptionIndex - 2; i++) {
          passage += info[i];
          if (i !== firstOptionIndex - 2) {
            passage += "<br>";
          }
        }
      } else {
        passage = null;
      }
      // const passage = firstOptionIndex >= 2 ? info[firstOptionIndex - 2] : null;
      const question = info[firstOptionIndex - 1];
      const answersText = info[lastOptionIndex + 1];
      const answers = answersText.split(",");
      let explanation = "";
      console.log(lastOptionIndex + 2, info.length);
      for (let i = lastOptionIndex + 2; i < info.length; i++) {
        explanation += info[i];
        if (i !== info.length) {
          explanation += "<br>";
        }
      }
      console.log([passage, question, choices, answers, explanation]);
      return {
        passage: passage,
        content: question,
        options: choices,
        explanation: explanation,
        answers: answers
      };
    },
    smartOneQuestion(str) {
      str = this.replaceAll(str, "</p><p>", "<br>");
      console.log(str);
      const question = {
        sat_passage_id: null,
        type: this.smartType,
        content: "",
        explanation: "",
        sat_question_tag_ids: this.smartTags,
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      };
      const passageText = {
        introduction: [],
        content: "",
        below_content: ""
      };
      if (this.smartType === "default") {
        const {
          passage,
          content,
          options,
          explanation,
          answers
        } = this.smartQuestion(str);
        passageText.content = passage;
        if (content) {
          question.content = content;
        }
        if (options && options.length > 0) {
          console.log(options);
          options.forEach((option, index) => {
            question.sat_options[index].title = "";
            question.sat_options[index].title = option;
          });
        }
        if (explanation) {
          question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          question.sat_answers[0].answers = answers;
        }
      } else {
        const { content, explanation, answers } = this.smartQuestionMath(str);
        if (content) {
          question.content = content;
        }
        if (explanation) {
          question.explanation = explanation;
        }
        if (answers && answers.length > 0) {
          question.sat_answers = [];
          answers.forEach((answer, index) => {
            question.sat_answers.push({
              answers: [answer]
            });
          });
        }
      }
      return {
        question,
        passage: passageText
      };
    },
    smartAllQuestions(inputStr) {
      this.smartQuestions = [];
      // inputStr = this.replaceAll(inputStr, "</p><p>", "<br>");
      // const regex = /(<p>&nbsp;<\/p><p>&nbsp;<\/p>)|(<figure.*?<\/figure>)/gs;
      // const matches = inputStr.match(regex);
      // const result = matches.map(match => match.trim());
      const result = inputStr.split("<p>&nbsp;</p><p>&nbsp;</p>");
      console.log(result);
      result.forEach(item => {
        const { question, passage } = this.smartOneQuestion(item);
        this.smartQuestions.push({
          question,
          passage
        });
      });
    },
    async updateQuestion() {
      await SAT.updateNewQuestion(this.questionId, {
        ...this.question,
        is_new: this.isNew,
        sat_passage_id: this.question.sat_passage_id,
        type: this.question.type,
        content: this.question.content,
        explanation: this.question.explanation,
        sat_question_tag_ids: this.question.sat_question_tag_ids
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      // this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-alert__description {
  font-size: 14px;
}
::v-deep .el-dialog__header {
  background: #efefef;
  padding-bottom: 20px;
}
::v-deep .el-dialog__footer {
  background: #efefef;
  padding-top: 20px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 600px;
  overflow: auto;
}
</style>
